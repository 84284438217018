<template>
  <h2 class="text-center mb-5">Terms & Conditions</h2>

  <div class="container">
    <div class="row">
      <div class="col mx-auto">
        <p>The promoter of this exhibition is the Global Network of Water Museums (WAMU-NET), which is a UNESCO-IHP “flagship initiative”, but which does not represent the opinions of UNESCO or IHP.</p>
        <p>There is no entry fee to participate in this exhibition, but all contributors must be members of WAMU-NET, or be affiliated to (and send their works through) a member</p>
        <p>After the deadline of December 20th 2021, no further entries for the exhibition will be accepted.</p>
        <p>By entering this exhibition, all museum members and individual members agree to be bound by these Terms and Conditions and declare to hold copyrights of the submitted works. WAMU-NET cannot be held responsible for improper use of copyrights by contributors or false declarations.</p>
        <p>By submitting any work, and agreeing to the present Terms and Conditions, the entrant is consenting the work to be used by WAMU-NET on its website, blogs and social media channels.</p>
        <p>All works and data collected will be stored by the Global Network in a secure online archive, which will be managed and used only by the WAMU-NET staff for the purpose of this exhibition.</p>
        <p>All personal data gathered by WAMU-NET will be kept safe according to European GDPR regulations (General Data Protection Regulation), as detailed below in the Notice on Privacy and Treatment of Personal Data.</p>
      </div>
    </div>
  </div>

  <h2 class="text-center my-5 text-uppercase">Notice on Privacy and Treatment of Personal Data</h2>

  <div class="container mb-5">
    <div class="row">
      <div class="col mx-auto">
        The Global Network of Water Museums, pursuant to art. 13 of the European Data Protection Regulation 679/2016 (GDPR), informs that the processing of personal data, represented by drawings, photos and videos, will be carried out both on-line and on paper in compliance with European legislation and the principles of correctness, lawfulness, transparency and confidentiality. Your personal data will be used for the purposes of promoting only the activity carried out by the Global Network of Water Museums. The granting of consent to the dissemination of personal data through websites, printed media and / or any other means of electronic distribution is optional. At any time, it is possible to exercise the rights indicated in articles 15,16,17,18 of the European GDPR with the revocation
        of this authorization, cancellation, rectification, or integration of data. These rights can be exercised by sending written notice to the data controller at: <b>info@watermuseums.net</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
