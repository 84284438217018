<template>
  <h2 class="text-center mb-5">About the Global Network of Water Museums</h2>

  <div class="container">
    <div class="row">
      <div class="col text-columns-2">
        <p><a href="https://watermuseums.net" target="_blank">The Global Network of Water Museums</a> (WAMU-NET) is a non-profit organization based in Venice which aims to <b>re-connect people with water</b> and to all forms of water heritage (both natural and cultural heritages). WAMU-NET was established to promote <b>water awareness education</b> and foster <b>holistic and inter-disciplinary approaches</b> aimed at valuing any kind of water assets and legacies with regard to their natural, social, cultural, artistic, and spiritual dimensions.</p>
        <p>In 2018, WAMU-NET was endorsed by the Council of the Inter-governmental Hydrological Programme as a <b>'flagship initiative' of UNESCO-IHP</b> with the Resolution n.5-XXIII, titled “The Global Network of Water Museum and UNESCO-IHP in support of water sustainability education and water awareness efforts”. In 2021, another Resolution (n.7-XXIV) was endorsed by the Intergovernmental Council of IHP to launch the process of creating a <b>World Inventory of water museums</b>.</p>
        <p>Water museums display a <b>unique repository of different forms of humankind's connections with water and its natural and cultural heritage</b>. They exhibit artefacts, techniques and traditional knowledge aimed at preserving the world's outstanding variety of water-related assets and values. This key knowledge - inherited from countless generations - can still inspire <b>new perceptions and behaviours towards water</b> and therefore encourage <b>more far-sighted water management today</b>.</p>
        <p>WAMU-NET cooperates with more than 70 museums and institutions located in 31 countries worldwide. The WAMU-NET network and all its members are seeking to promote innovative solutions to the global water crisis. While exploring the <b>fundamental values of any kind of water heritage</b> - whether natural or cultural, tangible or intangible - we look forward to re-connecting past water management practices to technical innovation in order to inspire more sustainable futures.</p>

        <p><a href="/terms-and-conditions">Terms & Conditions</a></p>
      </div>
    </div>
  </div>

  <div class="break-top my-5"><img src="/images/break-bottom.svg" alt="" /></div>

  <h2 class="text-center my-5">Participating Institutions</h2>
  <div class="container">
    <div class="row">
      <div class="col text-columns-2">
        <p>Austria - Danube Water-Adventure, Marktgemeinde Engelhartszell</p>
        <p>Bangladesh - Water Museum of Kalapara</p>
        <p>Belgium - Hidrodoe, Herentals</p>
        <p>Belgium - Musée de l’Eau et de la Fontaine, Ottignies</p>
        <p>Burkina Faso - Musée de l’Eau, Ouagadougou</p>
        <p>Canada - The Canadian Museum of Water</p>
        <p>China - National Water Museum of China</p>
        <p>China - Changjiang Civilization Museum, Wuhan</p>
        <p>China - Baiheliang Underwater Museum, Chongqing</p>
        <p>China - Yellow River Museum, Zhengzhou</p>
        <p>Croatia - AQUATIKA / Freshwater Aquarium, Karlovac</p>
        <p>France - Musée des Egouts, Paris</p>
        <p>Germany - Water Museum of Bavaria ‘Haus am Strom’, Jochestein</p>
        <p>Germany - Historisches Klaerwerk Krefeld</p>
        <p>Greece - World Water Museum, travelling</p>
        <p>Greece - Hydria Water Network, MIO-ECSDE</p>
        <p>Hungary - Duna Museum, Esztergom</p>
        <p>India - Water Museum of Udaipur, Rajasthan</p>
        <p>India - Living Waters Museum</p>
        <p>Iran - Yazd Water Museum</p>
        <p>Italy - River Navigation Museum, Battaglia Terme, Padua</p>
        <p>Italy - Water Museum of Ireland</p>
        <p>Italy - Ecomuseum Martesana, Milano</p>
        <p>Italy - Opificio delle Acque / Water Factory, Bologna</p>
        <p>Italy - MUSE / Science Museum, Trento</p>
        <p>Italy - Water Museum of Venice</p>
        <p>Italy - University of Venice Ca' Foscari</p>
        <p>Italy - Natural History Museum / Fondazione Musei Civici, Venice</p>
        <p>Italy - Regional Park Po Delta, Veneto Region</p>
        <p>Italy - Paese dell’Acqua / Waterland, Sassinoro, Benevento</p>
        <p>Italy - Ecomuseum of rice fields, rivers, and rural landscape of Mantua</p>
        <p>Italy - National Archaeological Museum of Adria / City of Adria</p>
        <p>Italy - HYDRA / Multimedia Museum of the Marmore Waterfalls, Terni</p>
        <p>Italy - Italian National Association of Land Reclamation - ANBI, Rome</p>
        <p>Macedonia - Water Museum of Ohrid</p>
        <p>Mexico - Museo del Agua ‘Agua para Siempre’, Tehuacan</p>
        <p>Morocco - Museum of Water Civilization in Morocco ‘Mohammed VI’</p>
        <p>Portugal - Museu da Agua, Lisbon</p>
        <p>Portugal - Parque Patrimonial das Aguas, Porto</p>
        <p>Romania – AQUASERV Water Museum, Tirgu Mures</p>
        <p>Romania – Water Museum ‘Leonida Truta’, Cluj</p>
        <p>Romania - Aquapic Water Museum, Timisoara</p>
        <p>Spain - Tribunal de les Aigües de la Vega de Valencia</p>
        <p>Spain - AGBAR / Museu de les Aigües, Cornellà de Llobregat</p>
        <p>Spain - Museu de la tècnica de Manresa / Parc de la Sèquia</p>
        <p>Spain - Museos de Mequinenza</p>
        <p>The Netherlands - Biesbosch Museumeiland, Werkendam</p>
        <p>The Netherlands - Haarlemmermeer Museum De Cruquius</p>
        <p>The Netherlands - Waterlinie Museum Fort Bij Vechten, Bunnik</p>
        <p>The Netherlands - Broekerveiling Water Museum</p>
        <p>The Netherlands - IHE DELFT Institute for Water Education</p>
        <p>The Netherlands - Nederlands Watermuseum</p>
        <p>The Netherlands - Museum Geelvinck</p>
        <p>Turkey - Ab-I Hayat Anatolian Water Civilizations Museum</p>
        <p>UK - National Waterways Museum, Ellesmere / Canal and River Trust</p>
        <p>UK - Museum of Water, travelling</p>
        <p>Uruguay - OSE Water Museum</p>
        <p>USA - Keepers of the Waters</p>
        <p>USA - Green Earth Alliance</p>
        <p>USA - Our Humanity Matters</p>
      </div>
    </div>
  </div>

  <h2 class="text-center my-5">Participating Individuals</h2>

  <div class="container">
    <div class="row">
      <div class="col text-columns-2">
        <p>France - Thierry Ruf</p>
        <p>Italy - Giuseppe La Spada</p>
        <p>Italy - Stefano Bertolucci</p>
        <p>The Netherlands - Sandra De Vries</p>
        <p>UK - Chris Wilmott</p>
        <p>USA - Deborah Wasserman</p>
      </div>
    </div>
  </div>

  <h2 class="text-center my-5">Curators</h2>

  <div class="container">
    <div class="row g-5">
      <div class="col-md-4">
        <b>Clive Adams</b><br />
        <p>Clive Adams is a British curator with a special interest in art which explores humanity's place and special responsibilities within nature. From 2006-20 he was the founding director of the Centre for Contemporary Art and the Natural World and has had an involvement with WAMU-NET since its inception.</p>
      </div>
      <div class="col-md-4">
        <b>Sara Ahmed</b><br />
        <p>Sara Ahmed is the Founder of the Living Waters Museum and an Adjunct Professor at the Centre for Water Research, Indian Institute of Science Education and Research, Pune, India. Sara serves on the boards of WaterAid India and Wetlands International South Asia, and as one of the Vice Presidents of WAMU-NET.</p>
      </div>
      <div class="col-md-4">
        <b>Giovanna Di Matteo</b><br />
        <p>Giovanna Di Matteo is a postdoctoral researcher at the University of Padua. She holds an interuniversity Ph.D. in Human Geography at the University of Padua and Ca’ Foscari University of Venice. Her research interests include Critical Tourism, Migration Studies, Mobility Studies, Island Studies, Landscape and Cultural Heritage.</p>
      </div>
      <div class="col-md-4">
        <b>Eriberto Eulisse</b><br />
        <p>Eriberto Eulisse is Executive Director and co-founder of the Global Network of Water Museums and Director of Civiltà dell’Acqua (‘Water Civilization’) International Centre. After specialization in museography and anthropology at the University of East Anglia, he worked in the Italian Alps as Curator of the Ethnographic Museum of Trentino.</p>
      </div>
      <div class="col-md-4">
        <b>Mário Mesquita</b><br />
        <p>Architect, Urban Planner and Artist. Professor at Faculdade de Arquitectura da Universidade do Porto (FAUP). Researcher at CITCEM, i2ADS, and Águas e Energia do Porto. PhD in Architecture by FAUP. Master’s in urban Environment Planning and Design FAUP/FEUP. Scientific Director of Parque Patrimonial das Águas.</p>
      </div>
      <div class="col-md-4">
        <b>Tanja Andrejasic Wechsler </b><br />
        <p>Tanja Andrejasic Wechsler is the creator of I AM WATER DROP - a reusable, glass water-drop-shaped bottle designed to inspire respect for water. She is also the founder and President of Our Humanity Matters (OHM), which aims at fostering more enlightened humanity to recognize water as the sacred foundation of Life.</p>
      </div>
    </div>
  </div>

  <div class="text-center my-5">Designed &amp; Developed by <a href="https://studioif.in/">Studio IF</a>, Ahmedabad, India</div>
</template>
