<template>
  <div class="break-bottom">
    <img src="/images/break-top.svg" alt="break-bottom" />
  </div>
  <footer class="my-5">
    <div class="container d-flex flex-column flex-md-row">
      <div class="d-flex flex-md-column justify-content-between mb-5 mb-md-auto">
        <div class="links order-2 order-md-1">
          <a href="/terms-and-conditions" class="d-md-block text-decoration-none mx-2 mx-md-auto">Terms & Conditions</a>
          <a href="https://www.watermuseums.net/contacts/" target="_blank" class="d-md-block text-decoration-none mx-2 mx-md-auto my-md-3">Contact Us</a>
        </div>

        <div class="social-icons order-1 order-md-2">
          <a href="https://www.facebook.com/wamunet/" class="text-secondary fs-5" target="_blank"><i class="fa-brands fa-facebook"></i></a>
          <a href="https://www.instagram.com/wamunet/" class="text-secondary mx-3 fs-5" target="_blank"><i class="fa-brands fa-instagram"></i></a>
          <a href="https://twitter.com/wamu_net" class="text-secondary me-3 fs-5"><i class="fa-brands fa-twitter" target="_blank"></i></a>
          <a href="https://jm.linkedin.com/company/water-museums-global-network" class="text-secondary fs-5"><i class="fa-brands fa-linkedin" target="_blank"></i></a>
        </div>
      </div>

      <div class="me-auto d-none d-lg-block ms-4">
        <a href="/"><img src="/images/irw-logo.png" alt="I Remember Water" width="180" class="img-fluid" /></a>
      </div>

      <div class="d-flex justify-content-between gx-3">
        <a href="https://www.watermuseums.net/" target="_blank" data-org="wamunet" class="me-5"><img src="/images/wamunet-logo.png" width="300" alt="" /></a>
        <div>
          <p style="margin-top: -3em">Supported by</p>
          <a href="https://www.livingwatersmuseum.org/" data-org="lwm" target="_blank"><img src="/images/LWM.png" alt="Living Waters Museum" width="200" /></a>
        </div>
      </div>
    </div>
    <div class="text-center mt-5 mb-3">&copy; Global Network of Water Museums 2022 | All rights reserved</div>
  </footer>
</template>
